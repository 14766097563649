import * as React from 'react';
import PropTypes from 'prop-types';
import styles from './Suggestion.module.css';
import { TMDB_IMAGE_URL_PREFIX } from '../../../services/tmdb';

const Suggestion = ({
  actor,
  onClick,
}: {
  actor: Actor;
  onClick: any;
}): JSX.Element => {
  return (
    <li className={styles.suggestionContainer} onClick={onClick}>
      <img
        className={styles.suggestionImage}
        src={`${TMDB_IMAGE_URL_PREFIX}${actor.photoUrl}`}
      ></img>
      <div className={styles.suggestionText}>{actor.name}</div>
    </li>
  );
};

export default Suggestion;
