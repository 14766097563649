import * as React from 'react';
import { Loader } from './features/loader/Loader';
const Actors = React.lazy(() => import('./features/actors/Actors'));

const App: React.FC = () => {
  return (
    <React.Suspense fallback={<Loader />}>
      <Actors />
    </React.Suspense>
  );
};

export default App;
