import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../config/store';
import TmdbAPI from '../../services/tmdb';

const initialState = {
  movies: [] as Movie[],
  isShown: false,
};

const moviesSlice = createSlice({
  name: 'movies',
  initialState,
  reducers: {
    setMovies: (state, action: PayloadAction<SelectedActors>): void => {
      // TmdbAPI.getMoviesWithActors(action.payload).then((result: Movie[]) => {
      //   state.movies = result;
      // });
    },
  },
});

export const { setMovies } = moviesSlice.actions;

export const selectMovies = (state: RootState) => state.movies;

export default moviesSlice.reducer;
