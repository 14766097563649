import { useRef, useState } from 'react';

export default function useAutocomplete({
  source,
  onChange,
}: {
  source: any;
  onChange: any;
}) {
  const [isShown, setIsShown] = useState(false);
  const [textValue, setTextValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const listRef = useRef<HTMLUListElement>(null);

  const clearSuggestions = () => {
    setSuggestions([]);
  };

  const getSuggestions = async (searchTerm: string) => {
    if (searchTerm && source) {
      const options = await source(searchTerm);
      setSuggestions(options);
      if (options) {
        setIsShown(true);
      }
    } else {
      setIsShown(false);
    }
  };

  const onTextChange = (searchTerm: string) => {
    setTextValue(searchTerm);
    clearSuggestions();
    getSuggestions(searchTerm);
  };

  const selectOption = (index: number) => {
    if (index > -1) {
      onChange(suggestions[index]);
      setTextValue(suggestions[index].name);
    }
    clearSuggestions();
  };

  const onSuggestionClick = (e: any) => {
    let nodes = Array.from(listRef.current.children);
    selectOption(nodes.indexOf(e.target.closest('li')));
  };

  return {
    isShown,
    setIsShown,
    bindInput: {
      onChange: (e: any) => onTextChange(e.target.value),
      value: textValue,
    },
    onSuggestionClick,
    suggestions,
    listRef,
  };
}
