import * as React from 'react';
import { Header } from '../todos/components/Header';
import ActorAutocomplete from './components/ActorAutocomplete';
import { addActor1, addActor2 } from './actorsSlice';
import { setMovies, selectMovies } from '../movies/moviesSlice';
import styles from './Actors.module.css';
import MoviesList from '../movies/MoviesList';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { selectedActorsSelect } from '../actors/actorsSlice';

const Actors = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const onSubmit = (e: React.SyntheticEvent) => {
    e?.preventDefault();
  };

  return (
    <div className={styles.container}>
      {<Header />}
      <form className={styles.formWrap} onSubmit={onSubmit} noValidate>
        <ActorAutocomplete
          id='actor1'
          placeholder='Actor 1'
          onOptionSelected={(selectedValue: Actor) =>
            dispatch(addActor1(selectedValue))
          }
        />
        <div className={styles.separator} />
        <ActorAutocomplete
          id='actor2'
          placeholder='Actor 2'
          onOptionSelected={(selectedValue: Actor) =>
            dispatch(addActor2(selectedValue))
          }
        />
        <MoviesList />
      </form>
    </div>
  );
};

export default Actors;
