import * as React from 'react';
import { useAppSelector } from '../../hooks/useAppSelector';
import { selectedActorsSelect } from '../actors/actorsSlice';
import TmdbAPI, { TMDB_IMAGE_URL_PREFIX } from '../../services/tmdb';
import styles from './MoviesList.module.css';

const MoviesList = () => {

const [joinedMovies, setJoinedMovies] = React.useState<Movie[]>([]);
const [joinedDocs, setJoinedDocs] = React.useState<Movie[]>([]);

  const { selectedActors } = useAppSelector(selectedActorsSelect);

  React.useEffect(() => {
    if (selectedActors?.actor1 && selectedActors?.actor2) {
      TmdbAPI.getMoviesWithActors(selectedActors).then((result: Movie[]) => {
        const movies: Movie[] = []
        const docs: Movie[] = []

        result.forEach((item: Movie)=>{
          if (item.genre_ids.indexOf(99) > -1) {
            docs.push(item)
          }
          else {
            movies.push(item)
          }
        })
        setJoinedMovies(movies);
        setJoinedDocs(docs);
      });
    }
  }, [selectedActors]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>{`Movies Together:`}</div>
      <div className={styles.list}>
        {joinedMovies?.map((joinedMovie: Movie) => (
          <div className={styles.movieItem} key={joinedMovie.id}>
            <img
              className={styles.moviePoster}
              src={`${TMDB_IMAGE_URL_PREFIX}${joinedMovie.poster_path}`}
            ></img>
            <div className={styles.movieTitle}>{`${joinedMovie.title} (${joinedMovie.release_date.slice(0, 4)})`}</div>
          </div>
        ))}
      </div>
      <div className={styles.separator}/>
      <div className={styles.line}></div>

      <div className={styles.header}>{`Documentaries Together:`}</div>
      <div className={styles.list}>
        {joinedDocs?.map((joinedDoc: Movie) => (
          <div className={styles.movieItem} key={joinedDoc.id}>
            <img
              className={styles.moviePoster}
              src={`${TMDB_IMAGE_URL_PREFIX}${joinedDoc.poster_path}`}
            ></img>
            <div className={styles.movieTitle}>{`${joinedDoc.title} (${joinedDoc.release_date.slice(0, 4)})`}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MoviesList;
