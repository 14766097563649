import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../config/store';

const initialState = {
  selectedActors: { actor1: null, actor2: null } as SelectedActors,
};
export const actorsSlice = createSlice({
  name: 'actors',
  initialState,
  reducers: {
    addActor1: (state, action: PayloadAction<Actor>): void => {
      state.selectedActors.actor1 = action.payload;
    },
    addActor2: (state, action: PayloadAction<Actor>): void => {
      state.selectedActors.actor2 = action.payload;
    },
  },
});

export const { addActor1, addActor2 } = actorsSlice.actions;

export const selectedActorsSelect = (state: RootState) => state.selectedActors;

export default actorsSlice.reducer;
