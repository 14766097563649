import * as React from 'react';
import PropTypes from 'prop-types';
import styles from './Header.module.css';
// import { useAppDispatch } from '../../../hooks/useAppDispatch';
// import { toggleModal } from '../../modal/modalSlice';

export const Header = (): JSX.Element => {
  return (
    <React.Fragment>
      <div className={styles.container}>
        <header>
          <h1 className={styles.header}>
            Hey Noa & Itamar!, See what movies both actors played in!
          </h1>
        </header>
        <div className={styles.line}></div>
      </div>
    </React.Fragment>
  );
};