import axios from 'axios';

const API_ACCESS_TOKEN =
  'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI0ODI0YTBjMjBkOGIxYmY2OTU0OGM2M2RiYjY2YmMxMCIsInN1YiI6IjUyYmVmYjhlMTljMjk1N2VjYTE4MjljNyIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.xgHKE3sQh88_52ISexgogsbaZoL3uOd27ljWLpsKWcw';

export const TMDB_SEARCH_ACTOR_URL =
  'https://api.themoviedb.org/3/search/person';
export const TMDB_SEARCH_MOVIES_URL = 'https://api.themoviedb.org/3/person';
export const TMDB_IMAGE_URL_PREFIX = 'https://image.tmdb.org/t/p/w500';

const getSuggestions = async (searchTerm: string): Promise<Actor[]> => {
  try {
    const response = await axios.get(
      `${TMDB_SEARCH_ACTOR_URL}?query=${searchTerm}`,
      {
        headers: {
          Authorization: API_ACCESS_TOKEN,
        },
      }
    );

    const suggestions: Actor[] = response.data.results.map((actor: any) => {
      return {
        id: actor.id,
        name: actor.name,
        photoUrl: actor.profile_path,
      };
    });
    return suggestions;
  } catch (e) {
    console.error(`Error from TMDB ${e}`);
    return null;
  }
};

const getMoviesWithActors = async (selectedActors: SelectedActors) => {
  const actor1Movies = await axios.get(
    `${TMDB_SEARCH_MOVIES_URL}/${selectedActors.actor1.id}/movie_credits`,
    {
      headers: {
        Authorization: API_ACCESS_TOKEN,
      },
    }
  );

  const actor2Movies = await axios.get(
    `${TMDB_SEARCH_MOVIES_URL}/${selectedActors.actor2.id}/movie_credits`,
    {
      headers: {
        Authorization: API_ACCESS_TOKEN,
      },
    }
  );

  const actor1CastMovieIds: number[] = actor1Movies.data?.cast.map(
    (castedMovie: any) => castedMovie.id
  );
  const actor2CastMovieIds: number[] = actor2Movies.data?.cast.map(
    (castedMovie: any) => castedMovie.id
  );

  const joinedCastedMovieIds = actor1CastMovieIds.filter((movieId: number) =>
    actor2CastMovieIds.includes(movieId)
  );

  const sharedMovies = actor1Movies.data?.cast.filter((actor1Movie: any) =>
    joinedCastedMovieIds.includes(actor1Movie.id) && actor1Movie.release_date
  );

  return sharedMovies;
};

const TmdbAPI = {
  getSuggestions,
  getMoviesWithActors,
};

export default TmdbAPI;
