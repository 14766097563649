import * as React from 'react';
import PropTypes from 'prop-types';
import styles from './ActorAutocomplete.module.css';
import Suggestion from './Suggestion';
import useAutocomplete from '../../../hooks/useAutocomplete';
import TmdbAPI from '../../../services/tmdb';

const ActorAutocomplete = ({
  id,
  placeholder,
  onOptionSelected,
}: {
  id: string;
  placeholder: string;
  onOptionSelected: any
}): JSX.Element => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { isShown, suggestions, bindInput, onSuggestionClick, listRef } =
    useAutocomplete({
      source: TmdbAPI.getSuggestions,
      onChange: onOptionSelected,
    });
  return (
    <React.Fragment>
      <input
        className={styles.input}
        ref={inputRef}
        name='input'
        placeholder={placeholder}
        id={id}
        {...bindInput}
      />
      {isShown && (
        <ul className={styles.suggestions} ref={listRef}>
          {suggestions.map(actor => (
            <Suggestion
              actor={actor}
              key={actor.id}
              onClick={onSuggestionClick}
            />
          ))}
        </ul>
      )}
    </React.Fragment>
  );
};

ActorAutocomplete.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default ActorAutocomplete;
